import Event from '../../components/views/event/event'
import { events as eventsContent } from '../../helpers/content';
import Layout from '../../components/layout';
import React from 'react';
import SEO from '../../components/seo';

const IndexPage = () => {
    
    const heroEvent = eventsContent.hero2022

    return (
    <Layout>
        <SEO title="Hero 2022 Event" />
        <Event event={heroEvent} />
    </Layout>
    )
}

export default IndexPage;